import { useStaticQuery, graphql } from "gatsby"
import PostTemplate from "../templates/PostTemplate"
import { Post, Category } from "../models/Post"
type categories = "all" | "projekty" | "verejne-obstaravanie"

// interface Post {
//   id: string
//   excerpt: string
//   date: string
//   path: string
//   title: string
//   category: string
//   categoryName: string
//   fluid: any
//   fixed: any
// }
export const categoryName = (category: string): string => {
  switch (category) {
    case "projekty":
      return "Projekty"
    case "verejne-obstaravanie":
      return "Verejné obstarávanie"
    default:
      return ""
  }
}
export const usePosts = (categories: string[] = []): Post[] => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query MyPosts {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
              path
              title
              categories
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 1000, maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    allMarkdownRemark.edges
      // .filter(edge => {
      //   //posts without dates are not posts
      //   if (!edge.node.frontmatter.date) return false
      //   if (category != "all" && edge.node.frontmatter.category !== category)
      //     return false
      //   return true
      // })
      .filter(edge => {
        //posts without dates are not posts
        if (!edge.node.frontmatter.date) return false
        // if (!edge.node.frontmatter.date) return false
        if (categories.length == 0) return true
        const items = edge.node.frontmatter.categories.filter(category =>
          categories.includes(category)
        )
        if (items.length > 0) return true

        return false
      })
      .map(
        (edge): Post => ({
          id: edge.node.id,
          excerpt: edge.node.excerpt,
          date: edge.node.frontmatter.date,
          path: edge.node.frontmatter.path,
          title: edge.node.frontmatter.title,
          categories: edge.node.frontmatter.categories.map(
            item =>
              ({
                name: categoryName(item),
                slug: item,
              } as Category)
          ),
          // categoryName: categoryName(edge.node.frontmatter.category),
          fluid: edge.node.frontmatter.featuredImage.childImageSharp.fluid,
          fixed: edge.node.frontmatter.featuredImage.childImageSharp.fixed,
        })
      )
  )
}
