import React, { ReactElement, FC } from "react"
import moment from "moment"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Img from "gatsby-image"
import { categoryName } from "../hooks/usePosts"
import { Icon } from "../components/Icon"
import { Post, Category } from "../models/Post"

const PostTemplate = ({ data }): ReactElement => {
  const post = usePost(data)
  const showDownloads = post => {
    return (
      <section className="mt-4 p-8 bg-gray-300 rounded">
        <h3 className="text-xl text-center">Dokumenty na stiahnutie</h3>
        <div className="flex flex-row items-center justify-center ">
          {post.download.map((item, index) => (
            <li key={index} className="flex items-center m-1">
              <a
                href={item[1]}
                className="inline-block mt-4 p-4 bg-blue-700 text-gray-100 rounded"
              >
                <Icon name="attach_file" className="inline" />
                <span>{item[0]}</span>
              </a>
            </li>
          ))}
        </div>
        <ul></ul>
      </section>
    )
  }
  return (
    <Layout>
      <Seo title={post.title} />
      <div className="blog-post">
        <h1 className="text-2xl pb-2">{post.title}</h1>
        <p className="text-xs pb-1">
          {post.categories &&
            `Kategória:${post.categories.map(item => ` ${item.name}`)} | `}
          {post.date && `Dátum: ${moment(post.date).format("DD.MM.YYYY")}`}
        </p>
        <div className="flex flex-wrap flex-row">
          <div className="flex-none w-full sm:w-32 md:w-64">
            <Img fluid={post.fluid} className="" />
          </div>
          <div
            className="flex-1 sm:ml-3"
            dangerouslySetInnerHTML={{ __html: post.html }}
          ></div>
        </div>
        {post.download && showDownloads(post)}
      </div>
    </Layout>
  )
}

export default PostTemplate

const usePost = (data: any) => ({
  html: data.markdownRemark.html,
  date: data.markdownRemark.frontmatter.date,
  path: data.markdownRemark.frontmatter.path,
  title: data.markdownRemark.frontmatter.title,
  categories: data.markdownRemark.frontmatter.categories.map(
    item =>
      ({
        name: categoryName(item),
        slug: item,
      } as Category)
  ),
  download: data.markdownRemark.frontmatter.download,

  fluid: data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid,
  fixed: data.markdownRemark.frontmatter.featuredImage.childImageSharp.fixed,
})

export const Query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        path
        title
        categories
        download
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
